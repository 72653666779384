let keepLiveRoute = [];
export default ({
    state: {
        keepLiveRoute
    },
    mutations: {
        ClearKeep(state, val) {
            let tmp = [];
            let arr = state.keepLiveRoute;
            arr.forEach((ele) => {
                if (ele != val) {
                    tmp.push(ele);
                }
            })
            state.keepLiveRoute = tmp;
        },
        SetKeep(state, val) {
            state.keepLiveRoute.push(val);
            // console.log("设置缓存路由",state);
        }
    },
    actions: {},
    modules: {}
});

