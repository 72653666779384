import router from '@/router';
import store from './store';
import { ElMessage } from 'element-plus';

//无需权限页面
const whiteList = ['/login'];

function addRoute(routers) {
    routers.forEach(item => {
        // 在Index路由中children添加子路由;
        router.addRoute('Index', {
            path: item.path,
            name: item.name,
            component: item.component,
            icon: item.icon,
            children: item.children
        });
    });
    router.options.routes = routers;
}

router.beforeEach(async (to, from, next) => {
    let isLogin = store.getters.token || sessionStorage.getItem('token'); // 是否登录
    if (isLogin) {
        const hasMenuList = store.getters.menuList && store.getters.menuList.length > 0;
        if (hasMenuList) {
            let routers = await store.dispatch('permissions/setMenuList');
            if (to.matched.length === 0) {
                addRoute(routers);
                next(routers[0].children[0].path);
            } else {
                addRoute(routers);
                next();
            }
        } else {
            let routers = await store.dispatch('permissions/setMenuList');
            try {
                if (routers.length > 0) {
                    addRoute(routers);
                    // 改动这里时，验证一下不同账号权限问题
                    // next({ path: routers[0].children[0].path });
                    next(routers[0].children[0].path)
                } else {
                    ElMessage.error({
                        message: '抱歉，你没有访问权限',
                        center: true
                    });
                    store.dispatch('user/setToken', null);
                    sessionStorage.removeItem('token');
                    store.dispatch('permissions/SET_MENU_LIST', null);
                }
            } catch (err) {
                store.dispatch('user/setToken', null);
                sessionStorage.removeItem('token');
                store.dispatch('permissions/SET_MENU_LIST', null);
                next('/login');
            }
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next();
        } else {
            next('/login');
        }
    }
});
