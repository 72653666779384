const user = {
    namespaced: true,
    state: {
        // 保存token
        token: sessionStorage.getItem('token') || null
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
            sessionStorage.setItem('token', token);
        }
    },
    actions: {
        // 设置token
        setToken(context, payload) {
            context.commit('SET_TOKEN', payload);
        }
    }
};
export default user;
