import { getAllDynamicRouterList } from '@/common/api/permissions/index.js';
import { defaultRoutesMap } from '@/router/index.js';

const permissions = {
    namespaced: true,
    state: {
        // 保存菜单列表
        menuList: []
    },

    mutations: {
        SET_MENU_LIST(state, data) {
            state.menuList = data;
        }
    },

    actions: {
        // 退出登录
        loginOut(context) {
            context.commit('SET_MENU_LIST', null);
            sessionStorage.removeItem('token');
        },
        // 设置菜单
        setMenuList(content, payload) {
            return new Promise((resolve, reject) => {
                getAllDynamicRouterList()
                    .then(response => {
                        const { code, Menus } = response;
                        if (code == 200) {
                            const routerNameArr = getRouterName(Menus);
                            defaultRoutesMap[0].redirect = Menus[0].children[0].path;
                            const addRoutersList = filterRouters(Menus);
                            content.commit('SET_MENU_LIST', addRoutersList);
                            resolve(addRoutersList);
                        }
                    })
                    .catch(err => {
                        reject(err);
                    });
            }).catch(error => {
                throw new Error(error);
            });
        }
    }
};

const getRouterName = (routers, nameArr = []) => {
    routers.forEach(item => {
        nameArr.push(item.name);
        if (item.children && item.children.length > 0) {
            getRouterName(item.children, nameArr);
        }
    });
    return nameArr;
};

// const filterRouters = (asyncRouter, rouerNames = []) => {
//     const arr = asyncRouter.filter(item => rouerNames.includes(item.name));
//     arr.forEach(citem => {
//         if (citem.children && citem.children.length > 0) {
//             citem.children = filterRouters(citem.children, rouerNames);
//         }
//     });
//     return arr;
// };

const filterRouters = (webRouer = []) => {
    var routers = [];
    webRouer.forEach(res => {
        defaultRoutesMap[0].children.forEach(item => {
            if (item.path == res.component) {
                item.name = res.name;
                item.icon = res.icon;
                var local_children = [];
                res.children.forEach(res1 => {
                    item.children.forEach(item1 => {
                        if (res1.component == item1.path) {
                            item1.name = res1.name;
                            local_children.push(item1);
                        }
                    });
                });
                item.children = local_children;
                routers.push(item);
            }
        });
    });
    return routers;
};

export default permissions;
