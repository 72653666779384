import { createStore } from 'vuex';
import permissions from './modules/permissions.js';
import user from './modules/user.js';
import getters from './getters';

export default createStore({
    modules: {
        permissions,
        user
    },
    getters
});
