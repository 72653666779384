import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import router from './router';
import 'element-plus/lib/theme-chalk/index.css';
import App from './App.vue';
import 'element-plus/lib/theme-chalk/base.css';
import $store from './router/route.js';
import store from './store';
import './permission.js';
// import Menu from '@element-plus/icons-vue'

// router.beforeEach(async (to, from, next) => {
//     const isLogin = store.getters.token || sessionStorage.getItem('token'); // 是否登录
//     console.log(isLogin);
//     if (isLogin) {
//         const hasMenuList = store.getters.menuList && store.getters.menuList.length > 0;
//         console.log(hasMenuList);
//         // if (to.path === '/login') {
//         //     next({ name: 'Index' });
//         // }
//     } else {
//         // if (to.path !== '/login' && to.path !== '/forget') {
//         //     next({ name: 'Login' });
//         // }
//     }
//     next();
// });

const app = createApp(App);
app.config.globalProperties.constStatusArray = [
    '待确认',
    '实施',
    '建模中',
    '设计中',
    '开发中',
    '测试中',
    '已交付',
    '已退回',
    '已取消'
];
app.config.globalProperties.confirmType = ['基层', '龙骨', '结构件'];
app.config.globalProperties.wareType = ['放水底盘', '底盘支架', '底盘面材', '壁板基材', '壁板面材', '顶板', '贴图', '洁具', '照明', '五金', '电器', '窗套', '门套'];
app.config.globalProperties.canvasUrl = 'http://120.26.47.168/';
// app.config.globalProperties.canvasUrl = 'http://localhost:2021';
app.config.globalProperties.$store = $store;
app.use(ElementPlus);
// app.use(Menu);
app.use(router);
app.use(store);
app.mount('#app');
