import axios from 'axios';
import qs from 'qs';
import store from '../store';
import router from '@/router';
import { ElMessage, ElLoading } from 'element-plus';
// axios.defaults.baseURL = '/'  //正式
// axios.defaults.baseURL = 'http://192.168.11.46:80/' //测试
// axios.defaults.baseURL = process.env.NODE_ENV == 'production' ? '/' : '/api';
const Axios = axios.create({
    baseURL: process.env.NODE_ENV == 'production' ? '/' : '/api'
});
//post请求头
// axios.defaults.headers.post["Content-Type"] ="application/x-www-form-urlencoded;charset=UTF-8";
//设置超时
// axios.defaults.timeout = 5000;

// axios.interceptors.request.use(
//     config => {
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     }
// );

// axios.interceptors.response.use(
//     response => {
//         if (response.status == 200) {
//             return Promise.resolve(response);
//         } else {
//             return Promise.reject(response);
//         }
//     },
//     error => {
//         return Promise.reject(error);
//     }
// );
export default {
    post(url, data, DOM, config) {
        let setting = {};
        let loadingInstance = '';
        let token = store.getters.token;
        if (DOM) {
            loadingInstance = ElLoading.service({
                target: document.querySelector('.' + DOM)
            });
        } else {
            loadingInstance = ElLoading.service({ fullscreen: true });
        }
        if (config) {
            setting = {
                token: token,
                'Content-Type': config
            };
        } else {
            //设置请求头信息
            setting = {
                token: token,
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            };
        }
        return new Promise((resolve, reject) => {
            Axios({
                method: 'post',
                url,
                data: config ? data : qs.stringify(data),
                headers: setting
            })
                .then((res) => {
                    loadingInstance.close();
                    if (this.close(res)) {
                        resolve(res.data);
                    }
                })
                .catch((err) => {
                    loadingInstance.close();
                    ElMessage.error(JSON.stringify(err.message));
                    reject(err);
                });
        });
    },

    get(url, data, DOM, loading) {
        //loading信息
        let loadingInstance = '';
        let token = store.getters.token;
        if (loading == undefined) {
            if (DOM) {
                loadingInstance = ElLoading.service({
                    target: document.querySelector('.' + DOM)
                });
            } else {
                loadingInstance = ElLoading.service({ fullscreen: true });
            }
        }
        let setting = {
            token: token,
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        return new Promise((resolve, reject) => {
            Axios({
                method: 'get',
                url,
                params: data,
                headers: setting
            })
                .then((res) => {
                    if (!loading) {
                        loadingInstance.close();
                    }
                    if (res.data.code === 444) {
                        resolve(res.data);
                        return;
                    } else {
                        if (this.close(res)) {
                            resolve(res.data);
                        }
                    }
                })
                .catch((err) => {
                    if (!loading) {
                        loadingInstance.close();
                    }
                    ElMessage.error(JSON.stringify(err.message));
                    reject(err);
                });
        });
    },

    exports(url, data, DOM, name) {
        let loadingInstance = '';
        let token = store.getters.token;
        if (DOM) {
            loadingInstance = ElLoading.service({
                target: document.querySelector('.' + DOM)
            });
        } else {
            loadingInstance = ElLoading.service({ fullscreen: true });
        }
        let setting = {
            token: token,
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        return new Promise((resolve, reject) => {
            Axios({
                method: 'get',
                url: url,
                params: data,
                headers: setting,
                responseType: 'blob'
            })
                .then((res) => {
                    var blob = new Blob([res.data]);
                    var filename = name;
                    var a = document.createElement('a');
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    var body = document.getElementsByTagName('body')[0];
                    body.appendChild(a);
                    a.click();
                    body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    loadingInstance.close();
                    resolve(res.data);
                })
                .catch((err) => {
                    loadingInstance.close();
                    ElMessage.error(JSON.stringify(err.message));
                    reject(err);
                });
        });
    },

    close(res) {
        if (res.data.code == 500) {
            //未登录
            window.sessionStorage.clear();
            router.push('/login');
            return false;
        }
        if (res.data.code === 1001) {
            ElMessage.error(res.data.msg);
            store.dispatch('user/setToken', null);
            // store.dispatch('permissions/setMenuList', null);
            store.dispatch('permissions/SET_MENU_LIST', null);
            window.sessionStorage.clear();
            router.push('/login');
            // location.reload();
            return false;
        }
        if (res.data.code !== 200) {
            ElMessage.error(res.data.msg);
            return false;
        }
        return true;
    },

    exportsTxt(url, data, DOM, name) {
        let loadingInstance = '';
        let token = store.getters.token;
        if (DOM) {
            loadingInstance = ElLoading.service({
                target: document.querySelector('.' + DOM)
            });
        } else {
            loadingInstance = ElLoading.service({ fullscreen: true });
        }
        let setting = {
            token: token,
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        return new Promise((resolve, reject) => {
            Axios({
                method: 'get',
                url: url,
                params: data,
                headers: setting
            })
                .then((res) => {
                    var filename = name;
                    const element = document.createElement('a');
                    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data));
                    element.setAttribute('download', filename);
                    element.style.display = 'none';
                    element.click();
                    loadingInstance.close();
                    resolve(res.data);
                })
                .catch((err) => {
                    loadingInstance.close();
                    ElMessage.error(JSON.stringify(err.message));
                    reject(err);
                });
        });
    },
    exportsTxtNew(url, data, DOM, name) {
        let loadingInstance = '';
        let token = store.getters.token;
        if (DOM) {
            loadingInstance = ElLoading.service({
                target: document.querySelector('.' + DOM)
            });
        } else {
            loadingInstance = ElLoading.service({ fullscreen: true });
        }
        let setting = {
            token: token,
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        return new Promise((resolve, reject) => {
            Axios({
                method: 'get',
                url: url,
                params: data,
                headers: setting
            })
                .then((res) => {
                    var filename = '';
                    if (name != '') {
                        filename = name;
                    } else {
                        filename = res.data.result.filename + '.json';
                    }
                    const element = document.createElement('a');
                    element.setAttribute(
                        'href',
                        'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data.result.jsondata)
                    );
                    element.setAttribute('download', filename);
                    element.style.display = 'none';
                    element.click();
                    loadingInstance.close();
                    resolve(res.data);
                })
                .catch((err) => {
                    loadingInstance.close();
                    ElMessage.error(JSON.stringify(err.message));
                    reject(err);
                });
        });
    }
};
