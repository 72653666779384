//路由
import { createRouter, createWebHashHistory } from 'vue-router';

// 默认路由
const constantRoutesMap = [
    {
        path: '/',
        redirect: '/component',
        name: 'Index',
        component: () => import('@/components/Container.vue'),
        children: []
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login/login.vue')
    },
    {
        path: '/forget',
        name: 'Forget',
        component: () => import('@/views/login/forget.vue')
    }
];
// 默认所有路由
export const defaultRoutesMap = [
    {
        path: '/',
        redirect: '/member',
        // redirect: redirectpath,
        name: 'Index',
        component: () => import('@/components/Container.vue'),
        children: [
            // {
            //     path: '/component',
            //     name: '工单',
            //     component: () => import('@/views/index/index.vue'),
            //     icon: 'el-icon-s-opportunity',
            //     children: [
            //         {
            //             path: '/component',
            //             name: '工单管理',
            //             component: () => import('@/views/component/index.vue')
            //         }
            //     ]
            // },
            {
                path: '/member',
                name: '构件',
                component: () => import('@/views/index/index.vue'),
                icon: 'el-icon-s-opportunity',
                children: [
                    {
                        path: '/member',
                        name: '构件管理',
                        component: () => import('@/views/member/index.vue')
                    },
                    {
                        path: '/memberUpload',
                        name: '构件上传',
                        component: () => import('@/views/member/upload.vue')
                    }
                ]
            },
            // {
            //     path: '/clientManagement',
            //     name: '客户管理',
            //     component: () => import('@/views/index/index.vue'),
            //     icon: 'el-icon-s-opportunity',
            //     children: [
            //         {
            //             path: '/clientInformation',
            //             name: '客户信息',
            //             component: () => import('@/views/clientManagement/clientInformation.vue')
            //         },
            //         {
            //             path: '/highSeasMessage',
            //             name: '公海信息',
            //             component: () => import('@/views/clientManagement/highSeasMessage.vue')
            //         }
            //     ]
            // },
            {
                path: '/user',
                name: '用户管理',
                component: () => import('@/views/index/index.vue'),
                icon: 'el-icon-user',
                children: [
                    {
                        path: '/user',
                        name: '用户信息',
                        component: () => import('@/views/user/index.vue')
                    },
                    {
                        path: '/userAdd',
                        name: '新增用户',
                        component: () => import('@/views/user/userAdd.vue')
                    }
                ]
            },
            {
                path: '/package',
                name: '套餐服务',
                component: () => import('@/views/index/index.vue'),
                icon: 'el-icon-s-platform',
                children: [
                    {
                        path: '/package',
                        name: '套餐列表',
                        component: () => import('@/views/package/package_details.vue')
                    },
                    {
                        path: '/postage',
                        name: '资费配置列表',
                        component: () => import('@/views/package/postage.vue')
                    },
                    {
                        path: '/smclassify',
                        name: '套餐分类列表',
                        component: () => import('@/views/package/package_sort.vue')
                    }
                ]
            },
            {
                path: '/packageOrder',
                name: '发票合同',
                component: () => import('@/views/index/index.vue'),
                icon: 'el-icon-s-ticket',
                children: [
                    // {
                    //     path: '/invoice',
                    //     name: '发票开票',
                    //     component: () => import('@/views/invoice/invoice.vue')
                    // },
                    // {
                    //     path: '/contract',
                    //     name: '合同管理',
                    //     component: () => import('@/views/invoice/contract.vue')
                    // },
                    {
                        path: '/packageOrder',
                        name: '套餐订单',
                        component: () => import('@/views/order-manage/packageOrderMain.vue')
                    },

                ]
            },
            {
                path: '/toolsManage',
                name: '工具管理',
                component: () => import('@/views/index/index.vue'),
                icon: 'el-icon-s-tools',
                children: [
                    {
                        path: '/toolsManage',
                        name: '工具管理',
                        component: () => import('@/views/tools-Manage/toolsManage.vue')
                    },
                    {
                        path: '/typeManage',
                        name: '类型管理',
                        component: () => import('@/views/tools-Manage/typeManage.vue')
                    }
                ]
            },
            // {
            //     path: '/orderManage',
            //     name: '订单管理',
            //     component: () => import('@/views/index/index.vue'),
            //     icon: 'el-icon-s-order',
            //     children: [
            //         {
            //             path: '/history',
            //             name: '购买记录',
            //             component: () => import('@/views/invoice/history.vue')
            //         }
            //     ]
            // },
            {
                path: '/model',
                name: '模型管理',
                component: () => import('@/views/index/index.vue'),
                icon: 'el-icon-box',
                children: [
                    {
                        path: '/modelUpload',
                        name: '上传模型',
                        component: () => import('@/views/model/modelUpload/index.vue')
                    },
                    {
                        path: '/modelIndex',
                        name: '模型库',
                        component: () => import('@/views/model/modelLibrary/index.vue')
                    },
                    {
                        path: '/modelAudit',
                        name: '模型审核',
                        component: () => import('@/views/model/modelAudit/index.vue')
                    },
                    {
                        path: '/chartletAudit',
                        name: '贴图审核',
                        component: () => import('@/views/model/chartletAudit/index.vue')
                    }
                ]
            },
            {
                path: '/customer_center',
                name: '客户中心',
                component: () => import('@/views/index/index.vue'),
                icon: 'el-icon-s-custom',
                children: [
                    {
                        path: '/intention_customer',
                        name: '入驻客户意向中心',
                        component: () => import('@/views/customerCenter/intentionCustomerCenter/index.vue')
                    },
                    {
                        path: '/brand_customer',
                        name: '品牌意向客户中心',
                        component: () => import('@/views/customerCenter/brandCustomerCenter/index.vue')
                    },
                    {
                        path: '/customer_search',
                        name: '搜索客户中心',
                        component: () => import('@/views/searchList/search.vue')
                    }
                ]
            },
            {
                path: '/content_manage',
                name: '内容管理',
                component: () => import('@/views/index/index.vue'),
                icon: 'el-icon-s-order',
                children: [
                    {
                        path: '/main_banner',
                        name: '主页banner',
                        component: () => import('@/views/contentManage/mainBanner/index.vue')
                    },
                    {
                        path: '/four_interests',
                        name: '四大权益',
                        component: () => import('@/views/contentManage/fourInterests/index.vue')
                    },
                    {
                        path: '/in_process',
                        name: '入驻流程',
                        component: () => import('@/views/contentManage/inProcess/index.vue')
                    }
                ]
            },
            {
                path: '/assembly',
                name: '运营中心',
                component: () => import('@/views/index/index.vue'),
                icon: 'el-icon-s-data',
                children: [
                    {
                        path: '/brandManage',
                        name: '品牌管理',
                        component: () => import('@/views/operatsCenter/artifactsManage/index.vue')
                    },
                    {
                        path: '/brand_manage',
                        name: '品牌信息审核',
                        component: () => import('@/views/operatsCenter/BrandManage/index.vue'),
                    },
                    {
                        path: '/brandManage',
                        name: '品牌管理',
                        component: () => import('@/views/category/brandManag.vue')
                    },
                ]
            },
            // {
            //     path: '/sort_manage',
            //     name: '部件管理',
            //     component: () => import('@/views/index/index.vue'),
            //     icon: 'el-icon-setting',
            //     children: [
            //         {
            //             path: '/ConstructSetup',
            //             name: '部件类目',
            //             component: () => import('@/views/sort_manage/ComponentCategory.vue')
            //         }
            //     ]
            // },
            // {
            //     path: '/system',
            //     name: '体系管理',
            //     component: () => import('@/views/index/index.vue'),
            //     icon: 'el-icon-s-home',
            //     children: [
            //         {
            //             path: '/sys/first',
            //             name: '体系配置',
            //             component: () => import('@/views/system/sys_first.vue')
            //         }
            //     ]
            // },
            {
                path: '/setting',
                name: '设置',
                component: () => import('@/views/index/index.vue'),
                icon: 'el-icon-setting',
                children: [
                    {
                        path: '/authManage',
                        name: '权限管理',
                        component: () => import('@/views/setting/authManage/authManageMain.vue')
                    },
                    {
                        path: '/accountManage',
                        name: '账号管理',
                        component: () => import('@/views/setting/accountManage/accountManageMain.vue')
                    },
                    {
                        path: '/personalSetting',
                        name: '个人设置',
                        component: () => import('@/views/setting/personalSetting/accountInfo.vue')
                    }
                ]
            },
            {
                path: '/sellManage',
                name: '销售管理',
                component: () => import('@/views/index/index.vue'),
                icon: 'el-icon-s-data',
                children: [
                    {
                        path: '/sellManage',
                        name: '销售账号管理',
                        component: () => import('@/views/sellManage/sellManage.vue')
                    },
                    {
                        path: '/sellManageAuthority',
                        name: '销售权限管理',
                        component: () => import('@/views/sellManage/sellManageAuthority.vue'),
                    },
                ]
            },
            // {
            //     path: '/classification_manage',
            //     name: '方案分类管理',
            //     component: () => import('@/views/index/index.vue'),
            //     icon: 'el-icon-more',
            //     children: [

            //         {
            //             path: '/mainclassification_manage',
            //             name: '一级分类管理',
            //             component: () => import('@/views/ClassificationManage/ClassificationManage/index.vue'),
            //         },
            //         {
            //             path: '/styleManage',
            //             name: '风格管理',
            //             component: () => import('@/views/category/styleManage.vue')
            //         },
            //         {
            //             path: '/charaManage',
            //             name: '特性管理',
            //             component: () => import('@/views/category/charaManage.vue')
            //         },
            //         {
            //             path: '/spatialArea',
            //             name: '空间区域',
            //             component: () => import('@/views/category/spatialArea.vue')
            //         }
            //     ]
            // },
            {
                path: '/category',
                name: 'SKU管理',
                component: () => import('@/views/index/index.vue'),
                icon: 'el-icon-s-help',
                children: [
                    {
                        path: '/category',
                        name: '模型审核',
                        component: () => import('@/views/category/category.vue')
                    }
                ]
            },
            {
                name: "体系匹配",
                path: "/systemList",
                icon: 'el-icon-s-help',
                component: () => import('@/views/index/index.vue'),
                children: [
                    {
                        name: "体系库",
                        path: "/systemStoreroom",
                        component: () => import('../views/systemList/systemOneList.vue')
                    },
                    // {
                    //     name: "标签库",
                    //     path: "/labelStoreroom",
                    //     component: () => import('../views/systemList/labelStoreroom.vue')
                    // },
                    {
                        name: "模型列表",
                        path: "/systemModelList",
                        component: () => import('../views/systemModelList/systemModelList.vue')
                    },
                    {
                        name: "分类管理",
                        path: "/classificationManagement",
                        component: () => import('../views/systemModelList/classificationManagement.vue')
                    }
                ]
            },
            // {
            //     path: '/sort_manage',
            //     name: '体系管理',
            //     component: () => import('@/views/index/index.vue'),
            //     icon: 'el-icon-setting',
            //     children: [
            //         {
            //             path: '/ComponentCategory',
            //             name: '体系配置',
            //             component: () => import('@/views/sort_manage/ComponentCategory.vue')
            //         }
            //     ]
            // }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login/login.vue')
    },
    {
        path: '/forget',
        name: 'Forget',
        component: () => import('@/views/login/forget.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    // routes: constantRoutesMap
    routes: defaultRoutesMap
});

export default router;
